<template>
    <v-container>

        <!-- HEADER -->
        <v-row class="mt-5" align="center">
            <v-col cols="12">
                <div class="title-section">
                    PRESUPUESTOS Y FINANZAS
                </div>
            </v-col>
            <v-col cols="4">
                <v-icon color="black" large>mdi-chart-box-outline</v-icon>
                <b>Presupuestos y Finanzas</b>
            </v-col>
            <v-col cols="4">
                <v-btn v-if="isAdmin || isAmbUser" class="normal-btn" color="primary" depressed @click="showCreateDialog">
                    <v-icon left>mdi-plus-circle-outline</v-icon>
                    Crear Presupuesto
                </v-btn>
            </v-col>
            <v-col cols="4">
                <kimsa-text-field
                    placeholder="Buscar"
                    append-icon="mdi-magnify"
                    outlined dense solo flat
                    @change="search = $event"
                />
            </v-col>
        </v-row>

        <!-- TABLE -->
        <v-data-table
            class="mt-5"
            :headers="headers"
            :loading="loading"
            :items="budgets"
            :search.sync="search"
            :items-per-page="5"
        >
            <template v-slot:item.client="{ item }">
                <v-icon>mdi-account-circle-outline</v-icon>
                <b v-text="item.client"></b>
            </template>
            <template v-slot:item.year="{ item }">
                <v-icon>mdi-calendar-outline</v-icon>
                {{ item.year }}
            </template>
            <template v-slot:item.user="{ item }">
                <v-icon>mdi-account-outline</v-icon>
                {{ item.user }}
            </template>
            <template v-slot:item.updated_at="{ item }">
                <v-icon>mdi-clock-outline</v-icon>
                {{ item.updated_at }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn class="mr-1 my-1" fab outlined x-small @click.stop="editItem(item)">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-if="isAdmin" class="mr-1 my-1" fab outlined x-small @click.stop="deleteItem(item)">
                    <v-icon color="primary">mdi-delete</v-icon>
                </v-btn>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mr-1 my-1" fab outlined x-small @click.stop="showCreatorFinancialDialog(item)" v-bind="attrs" v-on="on">
                            <v-icon color="warning">
                                {{ item.financialId ? 'mdi-file-document-edit-outline' : 'mdi-file-plus-outline' }}
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>{{ item.financialId ? 'Editar' : 'Crear' }} Estado Financiero</span>
                </v-tooltip>
                <v-btn class="mr-1 my-1" fab outlined x-small @click.stop="showPdfDownload(item)">
                    <v-icon color="info">mdi-download</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <!-- COMPONENTS -->
        <create-budget-dialog @success="loadBudgets" ref="creator" />
        <create-financial-dialog @success="loadBudgets" ref="financial" />
        <financial-pdf-download ref="downloadpdf"/>

        <kimsa-confirm
            title="Eliminar"
            content="¿Esta seguro que desea eliminar este Presupuesto?"
            persistent
            @confirm="onConfirmDelete"
            @cancel="currentId = null"
            :loading="loadingDelete"
            no-close-on-confirm
            ref="confirm"
        />

    </v-container>
</template>

<script>
import Axios from "@/utils/Axios";
import KimsaConfirm from "@/components/common/KimsaConfirm";
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import CreateBudgetDialog from "@/components/budget/CreateBudgetDialog";
import CreateFinancialDialog from "@/components/financial/CreateFinancialDialog";
import FinancialPdfDownload from "@/components/financial/FinancialPdfDownload";

export default {
    name: "Budget",
    components: {FinancialPdfDownload, CreateFinancialDialog, CreateBudgetDialog, KimsaTextField, KimsaConfirm},
    data() {
        return {
            loading: false,
            budgets: [],
            search: '',
            currentId: null,
            loadingDelete: false,
            headers: [
                {text: 'Cliente', value: 'client', class: 'grey white--text'},
                {text: 'Año', value: 'year', class: 'grey white--text'},
                {text: 'Autor', value: 'user', class: 'grey white--text'},
                {text: 'Actualización', value: 'updated_at', class: 'grey white--text'},
                {text: '', value: 'actions', sortable: false, class: 'grey'},
            ],
            loadingPDF: false,
        }
    },
    async mounted() {
        const vm = this
        await vm.loadBudgets()
    },
    methods: {
        async loadBudgets() {
            const vm = this
            vm.loading = true

            await Axios.post('budgets').then(res => {
                console.log('res loadBudgets',res)
                vm.budgets = res.data.result.budgets.map(budget => {
                    return {
                        id: budget.id,
                        client: budget.client.name,
                        year: budget.year,
                        user: budget.user.name,
                        financialId: budget.financial_statement?.id || null,
                        revenue: budget.revenue,
                        costSale: budget.cost_sale,
                        operatingExpenses: budget.operating_expenses,
                        otherExpenses: budget.other_expenses,
                        created_at: vm.$moment(budget.created_at).format('DD-MM-YYYY'),
                        updated_at: vm.$moment(budget.updated_at).format('DD-MM-YYYY'),
                    }
                })
                console.log('vm.budgets',vm.budgets)
            }).catch(er => {
                console.log('error loadBudgets', er)
            }).finally(() => {
                vm.loading = false
            })
        },
        showCreateDialog() {
            const vm = this
            vm.$refs.creator.show()
        },
        editItem(item) {
            const vm = this
            vm.$refs.creator.loadBudget(item.id)
        },
        deleteItem(item) {
            const vm = this
            vm.$refs.confirm.show()
            vm.currentId = item.id
        },
        showCreatorFinancialDialog(budget) {
            const vm = this
            console.log('budget',budget)
            let payload = {
                budgetId: budget.id,
                financialId: budget.financialId,
                client: budget.client,
                year: budget.year,
                revenue: budget.revenue,
                costSale: budget.costSale,
                operatingExpenses: budget.operatingExpenses,
                otherExpenses: budget.otherExpenses,
            }

            vm.$refs.financial.show(payload)
        },
        showPdfDownload(item) {
            const vm = this
            console.log('item',item)
            vm.$refs.downloadpdf.show(item)
            vm.currentId = item.id
        },
        async onConfirmDelete() {
            const vm = this
            vm.loadingDelete = true

            await Axios.delete(`budget/${vm.currentId}/delete`).then(res => {
                // console.log('res onConfirmDelete',res)
                vm.toast('Presupuesto eliminado')
            }).catch(er => {
                console.log('er',er)
                vm.toast('error onConfirmDelete', er)
            })

            vm.loadingDelete = false
            vm.currentId = null

            vm.$refs.confirm.hide()
            await vm.loadBudgets()
        },
    }
}
</script>

<style scoped>

.title-section {
    color: var(--v-primary-base);
}

</style>